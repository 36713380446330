// function getTimeRemaining(endtime) {
//   const total = Date.parse(endtime) - Date.parse(new Date());
//   const seconds = Math.floor((total / 1000) % 60);
//   const minutes = Math.floor((total / 1000 / 60) % 60);
//   const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
//   const days = Math.floor(total / (1000 * 60 * 60 * 24));

//   return {
//     total,
//     days,
//     hours,
//     minutes,
//     seconds
//   };
// }

// function initializeClock(id, endtime) {
//   const clock = document.getElementById(id);
//   const daysSpan = clock.querySelector('[data-value="days"]');
//   const hoursSpan = clock.querySelector('[data-value="hours"]');
//   const minutesSpan = clock.querySelector('[data-value="minutes"]');
//   const secondsSpan = clock.querySelector('[data-value="seconds"]');

//   function updateClock() {
//     // const timeinterval = setInterval(updateClock, 1000);
//     const t = getTimeRemaining(endtime);

//     daysSpan.innerHTML = t.days;
//     hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
//     minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
//     secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

//     const timeinterval = setInterval(() => {
//       updateClock();
// 		}, 1000);

//     if (t.total <= 0) {
//       clearInterval(timeinterval);
//     }
//   }

//   updateClock();
// }

// class to create a countdown timer
class CountdownTimer {
	// setup timer values
	constructor({ selector, targetDate, backgroundColor = null, foregroundColor = null }) {
		this.selector = selector;
		this.targetDate = targetDate;
		this.backgroundColor = backgroundColor;
		this.foregroundColor = foregroundColor;

        // grab divs on frontend using supplied selector ID
		this.refs = {
			days: document.querySelector(`${this.selector} [data-value="days"]`),
			hours: document.querySelector(`${this.selector} [data-value="hours"]`),
			mins: document.querySelector(`${this.selector} [data-value="minutes"]`),
			secs: document.querySelector(`${this.selector} [data-value="seconds"]`),
		};
	}

	getTimeRemaining(endtime) {
		const total = Date.parse(endtime) - Date.parse(new Date());
		const days = Math.floor(total / (1000 * 60 * 60 * 24));
		const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
		const mins = Math.floor((total / 1000 / 60) % 60);
		const secs = Math.floor((total / 1000) % 60);
		return {
			total,
			days,
			hours,
			mins,
			secs,
		};
	}

	updateTimer({ days, hours, mins, secs }) {
		this.refs.days.textContent = days;
		this.refs.hours.textContent = hours;
		this.refs.mins.textContent = mins;
		this.refs.secs.textContent = secs;
	}

	updateColors() {
		if (this.backgroundColor != null) {
			this.refs.days.style.background = this.backgroundColor;
			this.refs.hours.style.background = this.backgroundColor;
			this.refs.mins.style.background = this.backgroundColor;
			this.refs.secs.style.background = this.backgroundColor;
		}

		if (this.foregroundColor != null) {
			this.refs.days.style.color = this.foregroundColor;
			this.refs.hours.style.color = this.foregroundColor;
			this.refs.mins.style.color = this.foregroundColor;
			this.refs.secs.style.color = this.foregroundColor;
		}
	}

	startTimer() {
		const timer = this.getTimeRemaining(this.targetDate);
		// this.updateTimer(timer);
		// this.updateColors();
		let interval = setInterval(() => {
			const timer = this.getTimeRemaining(this.targetDate);

      if (timer.total >= 0) {
        this.updateTimer(timer);
      } else {
        // console.log('countdown complete');
        clearInterval(interval);
        document.querySelector(`${this.selector}`).classList.add('end');
        document.querySelector('.countdown-timer-text').classList.add('end');
      }
		}, 1000);
	}
}

// const timer = new CountdownTimer({
//   selector: "#clock1",
//   targetDate: new Date("Decemeber, 21 2022 18:00:00"),
// });

// timer.startTimer();

